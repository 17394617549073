import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SimpleGrid, Skeleton, Container, Stack, useMantineTheme } from '@mantine/core';
import { IconCircleCheck, IconCircleDashed, IconAd2 } from '@tabler/icons';

import{
  Card,
  Group,
  Image,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
} from '@mantine/core';

import {FeaturesCard}    from "./plan.js"
import {Premium}         from "./premium.js"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Shop() {
  const theme = useMantineTheme();

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  return (
    <Container mt={30} size="lg" py="xl">
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>

        <Stack>
          <FeaturesCard data={{
            title:"Basic",
            desc:"For Personal usage",
            list:[
              { label: '360', days: 360, price:26, id: "Basic-D" }
            ],
            features:[
              { label: '10 Hosts', icon:IconCircleCheck},
              { label: '60s Latency', icon: IconCircleCheck},
              { label: '20 Email Alerts', icon: IconCircleCheck},
              { label: 'Ping Monitor', icon: IconAd2},
              { label: 'Realtime Monitor', icon: IconCircleCheck}
            ]
          }}/>
        </Stack>
        <Stack>
          <FeaturesCard data={{
            title:"Pro",
            desc:"For Pro users",
            list:[
              { label: '360', days: 360, price:52, id: "Pro-D" }
            ],
            features:[
              { label: '20 Hosts', icon:IconCircleCheck},
              { label: '60s Latency', icon: IconCircleCheck},
              { label: '100 Email Alert', icon: IconCircleCheck},
              { label: 'Ping Monitor', icon: IconAd2},
              { label: 'Realtime Monitor', icon: IconCircleCheck},
            ]
          }}/>
        </Stack>
        <Stack>
          <FeaturesCard data={{
            title:"Team",
            desc:"For small team project",
            list:[
              { label: '360', days: 360, price: 218, id: "Team-D" }
            ],
            features:[
              { label: '100 Hosts', icon:IconCircleCheck},
              { label: '10s Latency', icon: IconCircleCheck},
              { label: '1000 Email Alert', icon: IconCircleCheck},
              { label: 'Ping Monitor', icon: IconAd2},
              { label: 'Realtime Monitor', icon: IconCircleCheck}
            ]
          }}/>
        </Stack>
        <Stack>
          <FeaturesCard data={{
            title:"Enterprise",
            desc:"For Business",
            list:[
              { label: '360', days: 360, price:528, id: "Enterprise-D" }
            ],
            features:[
              { label: '2000 Hosts', icon:IconCircleCheck},
              { label: '10s Latency', icon: IconCircleCheck},
              { label: '5000 Email Alert', icon: IconCircleCheck},
              { label: 'Ping Monitor', icon: IconAd2},
              { label: 'Realtime Monitor', icon: IconCircleCheck}
            ]
          }}/>
        </Stack>
      </SimpleGrid>
      <Premium/>
    </Container>
  );
}



