import React from "react";
import ReactDOM from "react-dom";
import axios from 'axios';

import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import Layout from "./components/Layout";
import createStore from "./store";
import './assets/main.css';
import './App.css';

const store = createStore( window.REDUX_DATA );

axios.defaults.baseURL = '/api';
axios.interceptors.request.use((request) => {
  request.start = performance.now()
  return request
});

axios.interceptors.response.use((response) => {
  response.config.stop = performance.now();
  return response
});

const jsx = (
    <ReduxProvider store={ store }>
        <Router>
            <Layout />
        </Router>
    </ReduxProvider>
);

const app = document.getElementById( "app" );
ReactDOM.hydrate( jsx, app );
console.log("client.js for developing")
