import React, { useEffect, useState } from 'react';
import { Card, Image, Text, Group, Badge, createStyles, Center, Button } from '@mantine/core';
import { UnstyledButton, Menu } from '@mantine/core';
import { List, ThemeIcon } from '@mantine/core';
import { IconCircleCheck, IconCircleDashed } from '@tabler/icons';

function Stars({features}) {
  return (
    <List
      spacing="xs"
      size="sm"
      center
      icon={
        <ThemeIcon color="teal" size={24} radius="xl">
          <IconCircleCheck size={16} />
        </ThemeIcon>
      }
    >
      {features.map((feature)=>{
        return (
          <List.Item
            icon={
              feature.icon?
              <ThemeIcon color="teal" size={24} radius="xl">
                <feature.icon size={16} />
              </ThemeIcon>
              :null
            }
          >
            {feature.label}
          </List.Item>
        )
      })}
    </List>
  );
}

const useDaysStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: 200,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  label: {
    alignItems: 'center',
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}));

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  total: {
    padding: theme.spacing.md,
    display: 'flex',
    fontWeight: 700,
    fontSize: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },

  per: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  imageSection: {
    padding: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));

export function FeaturesCard({data}) {

  const { classes } = useStyles();
  const {title, desc, list, features} = data;
  const [plan, setPlan] = useState(list[0]);

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.imageSection}>
          <Text weight={700} size={26}>
            {title}
          </Text>
      </Card.Section>

      <Group position="apart" mt="md">
        <div>
          <Text size="xs" color="dimmed">
            {desc}
          </Text>
        </div>
      </Group>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          Plan Details
        </Text>
        <Group spacing={8} mt={15} mb={-8}>
          <Stars features={features}/>
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
          <div  className={classes.total}>
            <Text weight={700} sx={{ lineHeight: 1 }}>
              {plan.price}$
            </Text>
          </div>
      </Card.Section>

    </Card>
  );
}
