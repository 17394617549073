import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
} from '@mantine/core';
import {
  IconActivity,
  IconAdjustmentsAlt,
  IconAnalyze,
  IconAntennaBars5,
  IconArrowAutofitRight,
  IconArrowUpCircle,
  IconBrandTabler,
  IconGauge,
  IconUser,
  IconCookie
} from '@tabler/icons';

const mockdata = [
  {
    title: '30 second monitoring',
    description:'Your website and API checks can be scheduled every 30 seconds! We support protocols such as HTTP, ICMP and Port.',
    icon: IconActivity,
  },
  {
    title: 'ICMP monitoring',
    description:'Monitoring with ping which alerts you when network glitches.',
    icon: IconAdjustmentsAlt,
  },
  {
    title: 'Worldwide servers',
    description:'Portalload monitors from more than a dozen of datacenters around the globe to guarantee redundancy and localize outages.',
    icon: IconAnalyze,
  },
  {
    title: 'Email Alerts',
    description:"Get email alerts when things go wrong",
    icon: IconGauge,
  },
  {
    title: 'System Load Monitoring',
    description:"Track your system load performance",
    icon: IconAntennaBars5,
  },
  {
    title: 'Realtime Monitoring',
    description:"realtime dashbaord with agent feeds",
    icon: IconArrowAutofitRight,
  },
  {
    title: 'Customized Metrics',
    description:"Support application metrics",
    icon: IconArrowUpCircle,
  },
  {
    title: 'Enterprise ready',
    description:"Support monitoring unlimited hosts",
    icon: IconBrandTabler,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.colors[theme.primaryColor][0],
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.colors[theme.primaryColor][0],
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export function FeaturesCards() {
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} p="xl">
      <feature.icon size={50} stroke={2} color={theme.colors[theme.primaryColor][0]} />
      <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text size="sm" color="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));
  return (
    <Container size="lg" py="xl">

      <Title order={2} className={classes.title} align="center" mt="sm">
        Monitoring features
      </Title>

      <Text color="dimmed" className={classes.description} align="center" mt="md">
      </Text>

      <SimpleGrid cols={4} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
        {features}
      </SimpleGrid>

    </Container>
  );
}
