import React,{useState,useEffect} from 'react';
import axios          from 'axios';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
import { useLocation } from "react-router-dom";

export function Authentication({match}) {

  const location = useLocation();
  const [email,setEmail] = useState(false);
  const [code,setCode]   = useState(false);
  const [count,setCount] = useState(0);
  const [message,setMessage] = useState(false);
  const [mode,setMode]       = useState(match.path)

  useEffect(()=>{
    setMessage(false)
    return ()=>{
    }
  },[email,code]);

  useEffect(()=>{
    if(count == 30){
      setTimeout(()=>{
        setCount(0);
      },20*1000)
    }
    return ()=>{
    }
  },[count]);

  const sendCode = () => {
        if(!email){
          return alert("please enter your email");
        }

        axios.post('/authorization', {
          address:email.toLowerCase()
        }).then(({data}) => {
          if (data.code == 0) {
            setCount(30);
          }
        });
  }

  const doAuth = () => {

        if(!code){
          return alert("please enter your email code");
          return;
        }

        if(!email){
          return alert("please enter your email");
        }

        axios.post('/login', {
            method:"email",
            email:email.toLowerCase(),
            mailcode:code,
        }).then(({data}) => {
          console.log(data)
          if(data.code == 0){
              if(data.data.userId == 1){
                window.location = "/admin"
              }else{
                window.location = "/console"
              }
          }else{
              setMessage(data.message)
          }
        }).catch((err) => {
            console.error(err);
        });

  }


  return (
    <Container size={420} my={80}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Portalload Dashboard
      </Title>

      {mode == "/register"?
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Already have an account?{' '}
        <Anchor
          href="/login"
          sx={(theme) => ({
            paddingTop: 2,
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
          })}
        >
        Sign in
        </Anchor>
      </Text>
        :
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{' '}
        <Anchor
          href="/register"
          sx={(theme) => ({
            paddingTop: 2,
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
          })}
        >
        Register
        </Anchor>
      </Text>
      }

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Text component="label" htmlFor="your-password" size="sm" weight={500}>
          Email
        </Text>
        <TextInput mt={5} mb={5} onChange={(e)=>{setEmail(e.target.value)}} placeholder="hello@gmail.com" size="sm" />


     <Group position="apart" mt={10} mb={5}>
        <Text component="label" htmlFor="your-password" size="sm" weight={500}>
          Access Code
        </Text>

        {count > 0?<Anchor
          disabled
          sx={(theme) => ({
            paddingTop: 2,
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
          })}
          >Sent</Anchor>:
        <Anchor
          href="#"
          onClick={(event) => sendCode()}
          sx={(theme) => ({
            paddingTop: 2,
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
          })}
        >
          Send Code
        </Anchor>
        }

      </Group>
        <TextInput name="code" placeholder="000000" onChange={(e) => setCode(e.target.value)}  />

        {message?
          <Alert icon={<IconAlertCircle size={16} />} title="Failed" mt={20} radius="md" variant="outline">
            {message}
          </Alert>
          :null}

        {mode == "/login"?
        <Button fullWidth mt="xl" onClick={()=>{doAuth()}}>
          Sign in
        </Button>
            :
            <div>
          <p style={{color:"#777",fontSize:"0.8rem"}}>By register, you agree to our terms and conditions</p>
        <Button fullWidth mt="xl" onClick={()=>{doAuth()}}>
          Register
        </Button>
            </div>
        }

      </Paper>
    </Container>
  );
}
