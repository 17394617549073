import React,{useState} from 'react';
import {Link,Redirect} from 'react-router-dom';
import { Anchor, Burger } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
  createStyles,
  Image,
  Container,
  Header,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  ActionIcon,
  SimpleGrid,
} from '@mantine/core';

import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons';
import { Check } from 'tabler-icons-react';

import { Hero } from '../sections/banner';
import { Footer } from '../sections/footer';
import { FeaturesCards } from '../sections/features';
import { Shop } from '../sections/shop';
import { Terms } from '../sections/legal.js';
import { Privacy } from '../sections/privacy.js';
import { Refund } from '../sections/refund.js';
import { Bullets } from '../sections/details';

const HEADER_HEIGHT = 84;

const useHeaderStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    width:"100%",
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 28,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  mainLinks: {
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: 13,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  secondaryLink: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottomColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 5 : 6],
  },
}));


export default function Home() {

  const links = {
      "mainLinks": [
        {
          "link": "",
          "label": "Home"
        },
        {
          "link": "#",
          "label": "Features"
        },
        {
          "link": "",
          "label": "Pricing"
        },
        {
          "link": "",
          "label": "Terms"
        },
        {
          "link": "",
          "label": "Privacy"
        },
        {
          "link": "",
          "label": "Refund Policy"
        },
        {
          "link": "",
          "label": "Login"
        }
      ]
  };

  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useHeaderStyles();
  const [active, setActive] = useState(0);

  const mainItems = links.mainLinks.map((item, index) => (
    <a
      href={item.link}
      key={item.label}
      className={cx(classes.mainLink, { [classes.mainLinkActive]: index === active })}
      onClick={(event) => {
        event.preventDefault();
        setActive(index);
      }}
    >
      {item.label}
    </a>
  ));

  return (
    <div>
    <Header  height={HEADER_HEIGHT} mb={0}>
      <Container className={classes.inner}>

        <Title className={classes.title}>
    <div style={{ width: 240, marginLeft: 'auto', marginRight: 'auto' }}>
    <Image
    radius="md"
    src="/images/portalload.png"
    alt="Random unsplash image"
    />
    </div>

        </Title>

        <div className={classes.links}>
          <Group spacing={0} position="right" className={classes.mainLinks}>
            {mainItems}
          </Group>
        </div>
      </Container>
    </Header>

      {active==0?<div>
        <Hero/>
        <FeaturesCards/>
        </div>:null}
      {active==1?<Bullets/>:null}
      {active==2?<Shop/>:null}
      {active==3?<Terms/>:null}
      {active==4?<Privacy/>:null}
      {active==5?<Refund/>:null}
      {active==6?<Redirect to="/login"/>:null}
      <Footer/>
    </div>
  );

}



