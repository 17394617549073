import React,{useState} from 'react';
import {Link,Redirect} from 'react-router-dom';
import { Anchor, Burger } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
  createStyles,
  Image,
  Container,
  Header,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  ActionIcon,
  SimpleGrid,
} from '@mantine/core';

import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons';
import { Check } from 'tabler-icons-react';

import { Hero } from '../sections/banner';
import { Footer } from '../sections/footer';
import { FeaturesCards } from '../sections/features';
import { Shop } from '../sections/shop';
import { Bullets } from '../sections/details';

const HEADER_HEIGHT = 84;

const useHeaderStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 28,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  mainLinks: {
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: 13,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',

    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none',
    },
  },

  terms: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    padding:"30px"
  },

  mainLinkActive: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottomColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 5 : 6],
  },
}));


export function Refund() {

  const links = {
      "mainLinks": [
        {
          "link": "",
          "label": "Home"
        },
        {
          "link": "#",
          "label": "Features"
        },
        {
          "link": "",
          "label": "Pricing"
        },
        {
          "link": "",
          "label": "Register"
        },
        {
          "link": "",
          "label": "Login"
        }
      ]
  };

  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useHeaderStyles({});
  const [active, setActive] = useState(0);

  const mainItems = links.mainLinks.map((item, index) => (
    <a
      href={item.link}
      key={item.label}
      className={cx(classes.mainLink, { [classes.mainLinkActive]: index === active })}
      onClick={(event) => {
        event.preventDefault();
        setActive(index);
      }}
    >
      {item.label}
    </a>
  ));

  return (
    <div className={classes.terms}>
    <h2 style={{fontSize:"2rem",fontWeight:"600"}}>Refund Policy</h2>
    <p>We offer a full money-back guarantee for all purchases made on our website.</p>
    <p>If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase.</p>
    <p>After the 14-day period you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product (or service) in the first two weeks after their purchase to ensure it fits your needs.</p>
    <p>If you have any additional questions or would like to request a refund, feel free to contact us(support@portalload.net).</p>
    </div>
  );

}



