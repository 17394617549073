import Home      from "./components/pages/Home";
import {Authentication}    from "./components/pages/Auth";

export default [{
        path: "/",
        component: Home,
        exact: true,
    },
    {
        path: "/register",
        component: Authentication,
        exact: true,
    },
    {
        path: "/login",
        component: Authentication,
        exact: true,
    }
];
