import React,{useState} from "react";
import { Switch, Route } from "react-router-dom";
import {
  MantineProvider,
} from '@mantine/core';
import routes from "../routes";

function Layout (){
  const [colorScheme, setColorScheme] = useState('dark');
  return (
    <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
      <Switch>
        { routes.map( route => <Route key={ route.path } { ...route } /> ) }
      </Switch>
    </MantineProvider>
  );
}

export default Layout;
